@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
    /* --PrimaryColor:hsl(199,100%,33%); */
    --text-primary: #1d2130;
    --black: #0b0706;
    --text-color: #525252;
    --text-secondary: #e9e9e9;
    --primary: #ffc107;
    --white: white;
    --green-tertiary: #fdeaaf;
    --green-secondary: #bef3c0;
    --green-primary: #70c174;
    --off-white: #ebf0f9;
    --border: #e5e5e5;
    --light-grey: #d0d0d0;
    --border-2-3: #c1cfe1;
    --white-smoke-background: #eff1f4;
    --border-2: #ddd;
    --light-steel-blue-border: #c1cfe1;
    --light-steel-blue-text: #c1cfe1;
}

.footer{
    width: 100%;
    position: relative;
    padding: 2rem 0;
    display: grid;
    background: var(--green-tertiary);
    align-items: center;
    justify-content: center;
    margin: auto;

    .videoDiv{
        position: absolute;
        height: 100%;
        width: 100%;
        

        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            background: var(--black);
        }

        &::after{
           content: ' ';
           position: absolute;
           background: rgba(10,84,107,0.307);
           top: 0;
           bottom: 0;
           right: 0;
           left: 0;
           mix-blend-mode:multiply ;
        }
    }
    .secContent{
        width: 100%;
        height: max-content;
        padding: 2rem initial;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        row-gap: 2rem;
        z-index: 100;

        .contactDiv{
            justify-content: space-between;
            flex-direction: column;
            gap: 1rem;

            .text{
                small{
                    font-size: 13px;
                    font-weight:400;
                    color: var(--black);
                }

                h2{
                    color: var(--black);
                    font-size: 1.8rem;

                }
            }
            .inputDiv{
               width: 100%;
               gap: 2rem;
               flex-direction: column;
               
               input{
                width: 100%;
                padding: .5rem .6rem;
                border-radius: 3rem;
                border: 1px solid var(--primary-color);
                outline: none;
                background: transparent;
                color: var(--text-primary);

                &::placeholder{
                    color: var(--black);
                    opacity: .7;
                }
               }

               .btn{
                width: 100%;
                justify-content: center;
                color: var(--black);
                font-weight: 500;
                font-size: medium;
                gap: .5rem;

                .icon{
                    font-size: 0.8rem;

                }
               }
            }
        }
    .footerCard{
        position: relative;
        padding: 1rem 1rem 4rem;
        gap: 2rem;
        border-radius: 1rem;
        background: var(--off-white);
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;

        .footerIntro{
            flex-basis: 50%;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            row-gap:.5rem ;
        }

        .logoDiv{
            .logo{
                color: var(--black);
                font-weight: 600;

                .icon{
                    font-size:15px ;
                    color: var(--primary-color);
                    margin-right: 10px;
                }
            }
        }

        .footerParagraph{
            font-size: 13px;
            color: var(--text-color);
        }

        .footerSocials{
            column-gap: .7rem;

            .icon{
                color: var(--primary-color);
                font-size: 0.4rem;

                &:hover{
                    color:var(--green-primary) ;
                }
            }
        }
       .footerLinks{
        flex-basis: 50%;
        width: 100%;
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;

        .linkGroup{
            .groupTitle{
                display: block;
                font-weight: 600;
                color: var(--black);
                margin-bottom: .5rem;
            }

            .footerList{
               font-size: 15px;
               color: var(--text-color);
               transition:.3s ease-in-out ;
               
               .icon{
                font-size: 15px;
                color: var(--off-white);
               }

               &:hover{
                color: var(--green-primary);
                transform: translateX(7px);


                .icon{
                    color: var(--primary-color);
                }

               }
            }
        }
       } 
       .footerDiv{
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background:var(--primary-color) ;
        padding: .5rem 2rem;

        small{
            font-size: 10px;
            color: var(--black);
            
        }
       }
    }
    }
}
//MEDIA QUERIES ================>

@media screen and (min-width: 500px){
    .footer{
        .secContent{
            .footerCard{
                .footerLinks{
                    grid-template-columns: repeat(2,1fr);
                    padding-bottom:1rem ;
                }

                .footerDiv{
                    justify-content: space-between;
                    flex-direction: row;

                    small{
                        font-size: 14px;
                        color: var(--black);
                        font-weight: 400;
                        font-family: 'poppins',sans-serif;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 660px){
    .footer{
        .secContent{  
        .contactDiv{
            flex-direction: row;
            justify-content: space-between;

            .inputDiv{
                width: 100%;
                flex-direction: row;
                justify-content: flex-start;


                .input{
                    width: 150%;


                    .btn{
                        width:max-content ;
                    }
                }
            }
        }
    }
    }
}

@media screen and (min-width: 840px){
    .footer{
        padding:5rem 0;
        .secContent{
           .footerCard{
            flex-direction: row;

            .footerLinks{
                grid-template-columns: repeat(3,1fr);
                padding-bottom: 1rem;
            }
           }
        }
    }
}

@media screen and (min-width: 1024px){
    .footer{
        justify-content: center;
        justify-items: center;
        .secContent{
            justify-content: center;
        justify-items: center;
            .footerCard{
                justify-content: center;
                 justify-items: center;
                padding: 3rem 5rem;

                .footerIntro{
                    .footerParagraph{
                        font-size: 15px;
                        color: var(--text-color);
                    }

                    .footerSocials{
                        column-gap: .5rem;
                        .icon{
                            color: var(--primary-color);
                            font-size: 25px;

                            &:hover{
                                color: var(--green-primary);
                            }
                        }
                    }
                }
                .footerLinks{
                    .linkGroup{
                        .footerList{
                            font: size 16px;
                            padding: 5px 0;
                        }
                    }
                }
            }
        }
    }
}