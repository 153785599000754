@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
    /* --PrimaryColor:hsl(199,100%,33%); */
    --text-primary: #1d2130;
    --black: #0b0706;
    --text-color: #525252;
    --text-secondary: #e9e9e9;
    --primary: #ffc107;
    --white: white;
    --green-tertiary: #eff7f2;
    --green-secondary: #bef3c0;
    --green-primary: #70c174;
    --off-white: #ebf0f9;
    --border: #e5e5e5;
    --light-grey: #d0d0d0;
    --border-2-3: #c1cfe1;
    --white-smoke-background: #eff1f4;
    --border-2: #ddd;
    --light-steel-blue-border: #c1cfe1;
    --light-steel-blue-text: #c1cfe1;
}
html{
  scroll-behavior:smooth ;
}

a{
    text-decoration:none ;
}

li{
    list-style: none;
}


.section{
    padding: 4rem 0 2rem;
}

.container{
  padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: auto !important;
}

.icon{
    font-size: 0.9rem;
    cursor: pointer;
}

.flex{
  display: flex;
  align-items: center;

}
.grid{
  display: grid;
  align-items: center;

}

.btn{
  background: var(--primary-color);
  color: var(--black);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a{
  color: var(--black);
  font-weight: 500;
}

.btn:hover{
  background: var(--green-primary);
}

/* .login-button{
    font-family: 'poppins', sans-serif;
    font-weight: 500;
    background-color: var(--primary);
    color: var(--black);
} */

img, video{
    max-width: 100%;
    height: auto;
    display: block;
}

input{
    font-size: 100%;
}

body{
    background: var(--off-white);
    margin: auto !important;
    
}

@media screen and (min-width: 540px){
  .container{
    width: 90% !important;
    margin: auto !important;
  }

  .section{
    padding: 9rem 0 4rem;

  }
}

@media screen and (min-width: 2560px){
  body{
    zoom: 1.7;
    .container{
    width: 90% !important;
    margin: auto !important;
  }
  }
}
