@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
    /* --PrimaryColor:hsl(199,100%,33%); */
    --text-primary: #1d2130;
    --black: #0b0706;
    --text-color: #525252;
    --text-secondary: #e9e9e9;
    --primary: #ffc107;
    --white: white;
    --green-tertiary: #eff7f2;
    --green-secondary: #bef3c0;
    --green-primary: #70c174;
    --off-white: #ebf0f9;
    --border: #e5e5e5;
    --light-grey: #d0d0d0;
    --border-2-3: #c1cfe1;
    --white-smoke-background: #eff1f4;
    --border-2: #ddd;
    --light-steel-blue-border: #c1cfe1;
    --light-steel-blue-text: #c1cfe1;
}

.home{
    width: 100%;
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    margin: auto;

.overlay{
    position: absolute;
    height: 100%; 
    width: 100%;
    background: rgb(82, 73, 54);
    top: 0;
    bottom: 0;
    z-index: 1;
    mix-blend-mode: hard-light;
}

video{
    position: absolute;
    height: 100%; 
    width: 100%;
    top: 0;
    bottom: 0;
    object-fit: cover;
}

.homeContent{
    width:100%;
    height: max-content;
    padding: 9rem 1.5rem 3rem;
    display: flex;
    row-gap: 1.5rem;
    flex-direction:column ;
    align-items: stretch;
    justify-content: center;
    margin: auto;
    color: var(--off-white);
    z-index: 100;
     align-items: center;
    justify-content: center;

    .textDiv{
        padding: 2rem 0;

    .smallText{
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
    }

    .homeTitle{
        position: relative;
        font-size: 1.7rem;
        font-weight: 700;
    }

    }

    .cardDiv{
        position: relative;
        padding: 2rem 1rem;
        background: var(--off-white);
        border-radius: 10px;
        gap: 1rem;

        label{
            display: block;
            color: var(--text-color);
            padding-bottom: 10px;
            font-size: 13px;
            font-weight: 500; 
        }

        .label_total{
            justify-content: space-between;
            align-items: center;


            .total{
                color: var(--text-color);
                font-size: 18px;
                padding-bottom: 10px;
            }
        }

        .input{
            height: 40px;
            padding: 0 1rem;
            background: var(--text-secondary);
            border-radius: 3rem;

            input{
                background: none;
                border:none ;
                outline: none;
                width: 100%;
                font-size: 13px;
                font-weight: 500;
                &::placeholder{
                    font-size: 13px;
                    color: var(--black);
                    opacity: .6;

                }
            }


            .icon{
               font-size: 25px;
               color: var(--primary-color)
               !important;
               margin-left: 10px;
            }

            input[type=range]{
                background: var(--primary-color);
                color: var(--black);
                font-weight: 500;
                font-size: medium;
            }
        }


        .searchOptions{
            align-items: center;
            position: absolute;
            background: var(--primary-color);
            color: var(--black);
            font-weight: 500;
            width: max-content;
            bottom:-0.7rem ;
            column-gap: .3rem;
           
            padding: 5px 1rem;
            border-radius: 3rem;
            left: 50%;
            transform: translate(-50%);
            justify-content: center;
            cursor: pointer;

            &:hover{
               background: var(--green-primary);
            }
            .icon{
                font-size: 12px;
                
            }

            span{
                font-size: 13px;
            }
        }

    }
    .homeFooterIcons{
        width: 100%;
        justify-content: space-between;

        .icon{
            font-size: 20px;
            margin: 0 5px;
            color: var(--off-white);
            font-weight: 600;
            &:hover{
                color: var(--green-primary);
            }
        }
    }
}

}

//MEDIA QUERIES ==============================>

@media screen and (min-width: 500px){
    .home{
        .homeContent{
            .textDiv{
            .smallText{
                font-size: 16px;
            }

            .homeTitle{
                position: relative;
                font-size: 2.3rem;
                width: max-content;

                &::after{
                    position: absolute;
                    content: '';
                    // background: var(--green-secondary);
                    height: 5px;
                    width: 145px;
                    right: 0px;
                    bottom: 5px;
                    z-index: -1;
                }
            }
            }
            .cardDiv{
            grid-template-columns: repeat(2, 1rf);

        } 
        }  
    } 
}

@media screen and (min-width: 660px){
    .home{
        .homeContent{
            .cardDiv{
                padding: 2rem 1rem;
                grid-template-columns: repeate(3,1fr);
            }
        }
    }
}

@media screen and (min-width: 1040px){
    .home{
        height:70vh;
    }
}

@media screen and (min-width: 940px){
    .home{
        height:85vh;

        .homeContent{
            padding-left: 0;
            padding-right: 0;

            .cardDiv{
                padding: 2rem;
                grid-template-columns: repeat(3,1fr);
                .input{
                    margin-bottom:1rem ;
                }

                .searchOptions{
                    bottom: -1rem;
                    padding: 8px 1rem;

                    span{
                        font-size: 17px;
                    }
                }
            }
        }
    }
}